.settings-cards .ant-collapse {
    border: none;
    padding: 5px 0;
    background-color: #FFF;
}

.settings-cards .ant-collapse-item {
    background: #F4F7FF;
    border: none;
}

.settings-cards .ant-collapse-header {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
}

.settings-cards .ant-collapse-content {
    border: none;
}