.app_layout {
  height: 100vh;
}
.app_layout_header {
  padding: 0;
  height: 60;
  box-shadow: 0px 1px 2px #d9dee8;
  border: 1px gray;
}
.app_layout_sidebar {
  background-color: #eef0f4;
  padding-top: 20px;
  height: 100%;
}

.app_layout_sidebar_menu {
  background-color: #eef0f4;
}
.app_layout_sidebar_menu__item {
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 30px 0px 30px 0px;
  height: 50px;
  display: flex;
  align-items: center;
}

.app_layout_sidebar_menu a {
  padding-left: 20px;
}

.app_layout_content {
  background-color: #ffffff;
  padding: 50px 20px 20px 20px;
  overflow-y: auto;
}

.app_layout_sidebar_menu > .ant-menu-item:hover,
.app_layout_sidebar_menu > .ant-menu-submenu:hover,
.app_layout_sidebar_menu > .ant-menu-item-active,
.app_layout_sidebar_menu > .ant-menu-submenu-active,
.app_layout_sidebar_menu > .ant-menu-item-open,
.app_layout_sidebar_menu > .ant-menu-submenu-open,
.app_layout_sidebar_menu > .ant-menu-item-selected,
.app_layout_sidebar_menu > .ant-menu-submenu-selected,
.app_layout_sidebar_menu > .ant-menu-item-selected {
  background-color: #fbfcfd;
  border-radius: 2px;
}

.ant-menu-item.app_layout_sidebar_menu__item.ant-menu-item-selected {
  background-color: white;
}
.ant-menu-item.app_layout_sidebar_menu__item.ant-menu-item-selected a {
  color: #252ce1 !important;
  font-weight: bold;
}

.ant-layout {
  overflow-y: hidden;
}
