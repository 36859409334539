.app_top_bar_wrapper {
	display: flex;
	box-shadow: 0px 1px 2px #d9dee8;
	border-bottom: 2px solid rgb(224, 218, 218);
}

.name-margin {
	margin: 0 5px 0 5px;
}
.black {
	color: black;
}
