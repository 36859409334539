.input-form {
    padding: 20px 4px;
    display: flex;
    width: 100%;
    /*margin-bottom: 2rem;*/
}

.table-row-light {
    background-color: #ffffff;
}

.table-row-dark {
    background-color: #f5f6f9;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected {
    color: #252ce1 !important;
    background-color: #e7eeff;
    border-bottom: 2px solid #252ce1;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu {
    padding-right: 30px !important;
    padding-left: 30px !important;
    margin: 0px !important;
}

.tab {
    padding: 3px 5px;
    border-radius: 18px;
    font-size: 13px;
    margin-right: 3px;
}

.Success {
    border: 1px solid #0c9e84;
    color: #0c9e84;
}

.Started,
.Requested,
.Inprogress {
    border: 1px solid #71bbff;
    color: #71bbff;
}

.Invalid,
.Halted,
.Failed {
    border: 1px solid #e14725;
    color: #e14725;
}

.Unspecified {
    border: 1px solid #808080;
    color: #808080;
}

.other-count {
    color: #252ce1;
    font-size: 10px;
    font-style: italic;
    font-weight: 500;
}

.icon-container {
    display: flex;
    align-items: center;
}

.icon-container span {
    margin-left: 5px;
}

.pointer {
    cursor: pointer;
}

.flex {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.items-center {
    align-items: center;
}

.ml3 {
    margin-left: 1rem;
}

.ml1 {
    margin-left: 0.5rem;
}

.ttc {
    text-transform: capitalize;
}

.b {
    font-weight: bold;
}

.gray {
    color: #818897;
}

.workflows-details-form .ant-form-item-label {
    text-align: left;
}

.workflows-details-form .ant-form-item-label>label::after {
    display: none;
}

.workflows-details-form .add-email-section .add-email-form-container {
    width: 100%;
    float: left;
    background-color: #FBFCFD;
    padding: 13px 10px;
}

.workflows-details-form .add-email-section .add-email-form-container .ant-row {
    flex-flow: column;
    margin-bottom: 15px;
}

.workflows-details-form .add-email-section .add-email-form-container .ant-row .ant-col {
    width: 100%;
    float: left;
    display: contents;
}

.workflows-details-form .add-email-section .add-email-form-container .ant-row:last-child {
    margin-bottom: 0;
}

.workflows-details-form .ant-row.add-email-section {
    position: relative;
}

.workflows-details-form .ant-row.add-email-section {
    margin-bottom: 15px;
}

.workflows-details-form .ant-row.add-email-section .add-email-form-close {
    width: 22px;
    height: 22px;
    border-radius: 100%;
    background-color: #EEF0F4;
    color: #818897;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    position: absolute;
    right: -10px;
    top: -10px;
    cursor: pointer;
}

.workflows-history-table-header {
    width: 100%;
    float: left;
    margin: 15px 0;
    padding: 10px 15px;
    background-color: #FBFCFD;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.workflows-history-table-header .ant-select.ant-select-single,
.workflows-history-table-header .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #F5F6F9;
    border: 1px solid #F5F6F9;
}

.full-width {
    width: 100%;
    float: left;
}

.workflows-history-table-header .ant-checkbox-inner {
    width: 24px;
    height: 24px;
    background-color: #FBFCFD;
    border-color: #383B40;
}

.workflows-history-table-header .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1890ff;
    border-color: #1890ff;
}

.workflows-history-table-header .ant-checkbox-inner::after {
    top: 47%;
    left: 24%;
    width: 7.714286px;
    height: 12.142857px;
}

.workflows-history-table-header .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.workflows-history-table-header .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    color: #363746;
}

.workflows-history-table-header .ant-checkbox+span {
    padding-right: 8px;
    padding-left: 8px;
    color: #383B40;
}

.workflows-history-table-header .ant-btn {
    border-color: transparent;
    box-shadow: none;
    background-color: transparent;
    color: #383B40;
}

.workflows-history-table-header .ant-btn span {
    text-decoration: underline;
    color: #383B40;
}

.ant-select-disabled .ant-select-selection-item {
    color: #4f4d4d;
}

.ant-col-5 {
    flex: 0 0 27.833333% !important;
    max-width: 27.833333%
}

.TriggerRadioGroup {
    margin-bottom: 15px;
}

.TriggerRadioGroup .ant-radio-wrapper {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #383B40;
}

.SelectOverflowDec {
    width: 100%;
    float: left;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #A6ACB7;
    margin-top: 10px;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.TriggerManualText {
    width: 100%;
    float: left;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #616775;
}
.TriggerRadioContent{
    /* margin-left: 52px; */
}

.TriggerRadioContent .ant-form-item {
    display: flex;
    flex-direction: column;
}

.TriggerRadioContent .ant-form-item-label>label {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #616775;
}

.workflow_details.ant-form-item {
    display: flex;
    flex-direction: column;
}

.workflow_details .ant-col.ant-col-16 {
    width: 100%;
    max-width: 100%;
}

.workflow_details>.ant-form-item-label {
    width: 100%;
    max-width: 100%;
    text-align: left;
}

.workflow_details>.ant-form-item-label>label {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #383B40;
}

.workflow_details .add-task-btn.ant-btn {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #1D1D1F;
    border: 1px solid #000000;
    border-radius: 5px;
}

.workflow_details .Workflow-Definition-container .ant-form-horizontal .ant-form-item .ant-form-item-label {
    text-align: left;
}

.workflow_details .ant-form-item-label>label::after {
    display: none;
}

.AddTaskDropdown {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
}

.AddTaskDropdown svg {
    margin-right: 8px;
}


.workflow-collapse-container {
    width: 100%;
    float: left;
}
.workflow-collapse-container .ant-collapse {
    width: 100%;
    float: left;
}
.workflow-collapse-container .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
font-size: 30px;
    color: #818897;
}
.workflow-collapse-container .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
    margin-bottom: 10px;
}
.workflow-collapse-container .ant-upload.ant-upload-drag p.ant-upload-hint {
    color: #A6ACB7;
    font-size: 12px;
}
.workflow-collapse-container .ant-btn {
    font-weight: 500;
font-size: 12px;
line-height: 20px;
color: #1D1D1F;
    background: #fff;
    border: 1px solid #000000;
box-sizing: border-box;
border-radius: 5px;
}
.KeyValueContainer {
	width: 100%;
	float: left;
	border: 1px solid #EEF0F4;
	padding: 15px 10px;
	margin-top: 10px;
}
.KeyValueContainer .ant-input{
    margin-top: 10px;
    font-size: 12px;
    color: #616775;
}
.KeyValueInputClose {
    display: flex;
    align-items: center;
}
.KeyValueInputClose .anticon {
    width: 40px;
    padding: 10px 5px;
    cursor: pointer;
}
.KeyValueContainer .ant-collapse-content {
    background-color: #FBFCFD;
    border-top: 1px solid #d9d9d9;
}
.workflow-collapse-container .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px;
    background-color: #FBFCFD;
        width: 100%;
    float: left;
}
.workflow-collapse-container .ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid #d9d9d9;
    margin-top: 15px;
    background-color: #fff;
}
.workflow-collapse-container .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    padding: 12px 16px;
    padding-right: 40px;
    background-color: #FBFCFD;
}
.workflow-collapse-container .ant-collapse > .ant-collapse-item {
    border-bottom: none;
    /* border-top: 2px solid #EEF0F4; */
    margin-top: 15px;
        width: 100%;
    float: left;
}
.workflow-collapse-container .ant-collapse {
    background-color: #fff;
        border: none;
}
.workflow-collapse-container .ant-collapse-content {
    border-top: solid 1px #EEF0F4;
}
.workflow-collapse-container .ant-collapse > .ant-collapse-item:first-child {
    margin-top: 0;
}
.Notifications-collapse-container {
    width: 100%;
    float: left;
}
.notifications-collapse-card {
        width: 100%;
    float: left;
    border: 1px solid #EEF0F4;
    padding: 15px 10px;
    margin-top: 10px;
    position: relative;
}
.notifications-event-select .ant-select-multiple .ant-select-selection-item-remove{
    display: none;
}
.notifications-event-select .ant-select-multiple .ant-select-selection-item {
    background-color: transparent;
    border-color: #fff;
    font-size: 12px;
    color: #383B40;
    font-weight: 600;
    margin: 0;
    padding: 0;
}
.notifications-event-select .ant-select-multiple .ant-select-selection-item-content {
    margin: 0;
}
.notifications-event-select .ant-select-multiple .ant-select-selection-item-content:after {
    content: ",";
}
.ant-select-selection-overflow .ant-select-selection-overflow-item:nth-last-child(2) .ant-select-selection-item-content:after{
    display: none;
}
.workflow-collapse-container .ant-form-vertical .ant-form-item-label > label,
.workflow-collapse-container .ant-form-item-label > label,
.input-label {
    height: auto;
    font-size: 12px;
    color: #616775;
}
.notifications-email-select .ant-select-multiple .ant-select-selection-item {
    font-size: 12px;
    background-color: #252CE1;
    color: #fff;
    border-radius: 25px;
}
.notifications-email-select .ant-select-multiple .ant-select-selection-item-remove > .anticon {
    vertical-align: -0.2em;
    color: #fff;
}
.notifications-collapse-card .ant-form-item:last-child {
    margin-bottom: 0;
}
.notifications-collapse-card .DropdownAction .ant-btn{
border: none;
    padding: 0;
    margin: 0;
}
/* .notifications-collapse-card .DropdownAction .ant-dropdown-trigger {
    display: none;
} */
.notifications-collapse-card .DropdownAction .anticon {
    font-size: 20px;
    color: #363746;
}
.notifications-collapse-card .DropdownAction {
        position: absolute;
    right: 5px;
    top: 10px;
        z-index: 2;
}
.selectEventDropdown .ant-select-item .ant-select-item-option-content{
    position: relative;
    overflow: visible;
}
.selectEventDropdown .ant-select-item .ant-select-item-option-content:after{
    content: '';
    position: absolute;
    right: -3px;
    width: 20px;
    height: 20px;
    border: 2px solid #DADEE8;
    border-radius: 2px;
}
.selectEventDropdown .ant-select-item.ant-select-item-option-selected .ant-select-item-option-content:after {
    right: -17px;
    z-index: 3;
    background-color: #252CE1;
    border-color: #252CE1;
}
.selectEventDropdown .ant-select-item.ant-select-item-option-selected .ant-select-item-option-state .anticon{
    color: #fff;
    position: relative;
    z-index: 9;
}
.workflow-drawer-footer {
    width: 100%;
    float: left;
}
.rc-virtual-list-scrollbar{
    display: flex !important;
}

.notifications-view .ant-collapse-content-box{
    height: 600px !important;
}
.TriggerRadioGroup {
    margin-bottom: 15px;
}
.TriggerRadioGroup .ant-radio-wrapper {
font-weight: 400;
font-size: 12px;
line-height: 20px;
color: #383B40;
}
.SelectOverflowDec {
    width: 100%;
    float: left;
    font-weight: 400;
font-size: 12px;
line-height: 14px;
letter-spacing: 0.01em;
color: #A6ACB7;
margin-top: 10px;
}
.mb-0 {
    margin-bottom: 0 !important;
}
.TriggerManualText {
    width: 100%;
    float: left;
    font-weight: 400;
font-size: 12px;
line-height: 20px;
color: #616775;
}
.Workflow-Definition-container {
    width: 100%;
    float: left;
}
.Workflow-Definition-label {
    width: 100%;
    float: left;
        height: auto;
    font-size: 12px;
    color: #616775;
        padding: 0 0 8px;
            line-height: 1.5715;
}
.w-100 {
    width: 100% !important;
}
.Workflow-Definition-container .ant-collapse-item {
width: 100%;
    float: left;
}
.workflow-collapse-container .Workflow-Definition-container .ant-collapse > .ant-collapse-item {
    background: #F0F1FB;
    border-radius: 5px;
        overflow: hidden;
}
.workflow-collapse-container .Workflow-Definition-container .ant-collapse-content {
    background: #F0F1FB;
    border-top: solid 1px #EEF0F4;
    width: 100%;
    float: left;
}
.workflow-collapse-container .Workflow-Definition-container .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
        width: 100%;
    float: left;
}
.workflow-collapse-container .Workflow-Definition-container .ant-collapse > .ant-collapse-item > .ant-collapse-header {
background-color: #EAEBF9;
}
.workflow-collapse-container .Workflow-Definition-container .ant-collapse {
    background-color: transparent;
    border: none;
}
.Workflow-Definition-container .ant-form-horizontal .ant-form-item{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.Workflow-Definition-container .ant-form-horizontal .ant-form-item.form-text-area{
    align-items: flex-start;
}
.Workflow-Definition-container .ant-form-horizontal .ant-form-item.form-text-area .ant-form-item-label > label {
    padding-top: 10px;
}
.Workflow-Definition-container .ant-form-horizontal .ant-form-item .ant-form-item-label {
    padding-bottom: 0;
}
.Workflow-Definition-container .ant-form-horizontal .ant-form-item .ant-input {
    margin-top: 0;
}
.Workflow-Definition-container .ant-form-horizontal .ant-form-item-label {
    min-width: 95px;
}
.workflow-collapse-container .Workflow-Definition-container .ant-collapse-content .ant-collapse-content-box{
    background-color: #F0F1FB;
}
.Workflow-Definition-container .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}
.Workflow-Definition-container .Arguments-Action-Set {
    width: 100%;
    float: left;
        display: flex;
    flex-direction: row;
    align-items: center;
        margin-bottom: 8px;
}
.Workflow-Definition-container .Arguments-Action-Set:last-child {
    margin-bottom: 0;
}
.Workflow-Definition-container .Arguments-remove {
        color: #D14545;
    margin: 0 20px;
    font-size: 18px;
    cursor: pointer;
}
.Workflow-Definition-container .Arguments-Action-Set .ant-btn {
        border: 0.5px solid #383B40;
    border-radius: 2px;
    background-color: transparent;
    color: #383B40;
}
.workflow-collapse-container .ant-btn.trigger-rmove-btn {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    padding: 0;
    color: #D14545;
    box-shadow: none;
    height: auto;
}
.workflow-collapse-container .ant-btn.trigger-rmove-btn span {
    margin-left: 5px;
}
.tabHeader {
        display: flex;
    align-items: center;
    font-weight: 700;
font-size: 11px;
line-height: 20px;
color: #1D1D1F;
}
.tabHeader span {
    margin-left: 12px;
}
.workflow-collapse-container [data-rbd-droppable-id="droppable"] {
width: 100%;
float: left;
}
.DraggableSet {
    width: 100%;
    float: left;
}
.workflow-collapse-container .DraggableSet > div {
   width: 100%;
float: left; 
margin-bottom: 15px;
}

.WorkspaceActionContainer {
    width: 100%;  
    float: left;
  }
  .WorkspaceActionSet {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .close-icon {
    position: absolute;
      top: 0px;
      right: 5px;
      cursor: pointer;
  }
  .UpdateCancelBtnSet {
    width: 100%;
    float: left;
        display: flex;
      flex-direction: column;
  }
  .UpdateCancelBtnSet .WorkspaceActionSetWarning {
        margin-bottom: 2px;
  }
  .UpdateCancelBtnContainer .ant-btn-dangerous.DeleteBtn {
    background: #F4F7FF;
      border: 1px solid #D14545;
      padding: 4px 15px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #D14545;
  } 
  .UserDeleteAction .UpdateCancelBtnContainer .CancelBtn {
  background: #F4F7FF;
  border: 1px solid #989898;
  border-radius: 2px;
  color: #989898;
  }
  .align-items-start {
    align-items: flex-start !important;
  }
  .edit-box-container-inner.edit-user {
    display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      min-height: 73px;
      height: 165px;
      z-index: 2;
      background: #F4F5FB;
      border-radius: 5px;
      border-top: solid 1px #F4F5FB;
  }
  .edit-box-container-inner {
    width: 100%;
    float: left;
    display: flex;
    height: 73px;
    padding: 16px 12px;
      flex-direction: row;
      align-items: center;
    justify-content: space-between;
  }
  .edit-box-container.active {
  background: #F4F5FB;
  }
  .edit-user-select {
    width: 100%;
    float: left;
  }
  .edit-user-select .ant-select {
    width: 100%;
    float: left;
  }
  .edit-user-select .ant-select .ant-select-selector{
  border: 1px solid #DADEE8;
      border-radius: 2px;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #616775;
      height: 24px;
      background-color: transparent;
  }
  .edit-user-select .ant-select .ant-select-selector .ant-select-selection-item {
        line-height: 22px;
  }
  .edit-box-container-inner.edit-user .UpdateCancelBtnContainer .CancelBtn{
    background-color: transparent;
  }
  
  
  
  .UpdateCancelBtnContainer .DeleteBtn {
    font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  font-family: 'Nunito Sans', sans-serif;
  color: #FFFFFF;
  background: #CF5C5C;
  border-radius: 2px !important;
  border: solid 1px #CF5C5C;
  margin-right: 8px;
  }
  .UpdateCancelBtnContainer .DeleteBtn span {
      margin-top: 3px;
      margin-left: 5px;
  }
  .UpdateCancelBtnContainer {
    display: flex;
    align-items: center;
  }
  .UpdateCancelBtnContainer .UpdateBtn {
    font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  font-family: 'Nunito Sans', sans-serif;
  margin-right: 8px;
      border-radius: 2px !important;
  }
  .UpdateCancelBtnContainer .CancelBtn {
      font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #383B40;
  font-family: 'Nunito Sans', sans-serif;
  margin-right: 8px;
  border-radius: 2px;
  border: 1px solid #383B40;
  }
  .UpdateCancelBtnContainer .ant-btn-dangerous{
  padding-right: 0;
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;
  color: #E2A4A4;
  }
  .UpdateCancelBtnContainer .ant-btn-dangerous > span {
        margin-top: 3px;
      margin-left: 5px;
  }

  .ant-form-horizontal1{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum"
  }
  .ant-form-horizontal1 .ant-form-item-control{
    text-align: left;
    margin-top: -12px;
    margin-left: -53px;
  }

  .ant-form-horizontal1 .ant-form-item-label{
      text-align: left;
  }
  .ant-form-horizontal1 .Arguments-Action-Set .ant-form-item-label{
    text-align: left;
    margin-top: -1px;
    margin-left: 1px;
    
}


.ant-form-horizontal1 .Arguments-Action-Set .ant-form-item-control {
    text-align: left;
    margin-top: -13px;
    /* margin-left: 27px */
}

.depends .ant-form-item-control{
    width: 189px;
    /* margin-left: 18px !important; */
    }

.depends .ant-form-item-label{
    /* width: 189px; */
    min-width: 94px  !important;
        max-width: 94px  !important;
    }
    
    .commounForm .ant-form-item-control{
        margin-left: -36px ;
    }

    .update-button .trigger-rmove-btn{
        margin-left: 38px !important;
    }

    .commounForm .ant-form-item-label{
        min-width: 150px;
        max-width: 150px;   
    }