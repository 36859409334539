.worspace_card {
  width: 300;
  background: #ffffff;
  /* 02-Grays/Gray04 */

  border: 1px solid #dadee8;
  /* Elevation/Light/Small */

  box-shadow: 0px 1px 2px #d9dee8;
  border-radius: 5px;
  height: 250px;
  width: 350px;
}

.worspace_card > .ant-card-head {
  border: none;
}

.worspace_card > .ant-card-body {
  padding-top: 5px;
}

.worspace_card a {
  color: black;
}

.worspace_card a h2 {
  padding: 0;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 3px;
}

.my-workspace {
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
}