.ant-table-thead th {
	background-color: #f4f7ff !important;
}

.table-row-light {
	background-color: #ffffff;
}
.table-row-dark {
	background-color: #f5f6f9;
}

input:focus,
textarea {
	outline: none !important;
}

.secret-input {
	border: none;
	background-color: transparent;
	outline: 0;
	width: 100%;
}

.show-hide {
	font-size: 12px;
	text-decoration: underline;
	cursor: pointer;
}
.tag-container {
	display: flex;
	flex-wrap: wrap;
}

.tags {
	background-color: #f4f7ff;
	border-radius: 2px;
	padding: 1px 4px;
	border: 0.5px solid #4e53d0;
	margin-right: 0.5rem;
	font-size: 12px;
	margin-bottom: 5px;
}

.default-search {
	margin-top: 20px;
	min-width: 130px;
	max-width: 250px;
	margin-right: 45%;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
	padding: 8px 14px;
}
