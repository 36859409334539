.input-form {
  padding: 20px 4px;
  display: flex;
  width: 100%;
  /*margin-bottom: 2rem;*/
}

.table-row-light {
  background-color: #ffffff;
}
.table-row-dark {
  background-color: #f5f6f9;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #252ce1 !important;
  background-color: #e7eeff;
  border-bottom: 2px solid #252ce1;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  padding-right: 30px !important;
  padding-left: 30px !important;
  margin: 0px !important;
}

.tab {
  padding: 3px 5px;
  border-radius: 18px;
  font-size: 13px;
  margin-right: 3px;
}

.Success {
  border: 1px solid #0c9e84;
  color: #0c9e84;
}
.Started,
.Requested,
.Inprogress {
  border: 1px solid #71bbff;
  color: #71bbff;
}

.Invalid,
.Halted,
.Failed {
  border: 1px solid #e14725;
  color: #e14725;
}

.Unspecified {
  border: 1px solid #808080;
  color: #808080;
}

.other-count {
  color: #252ce1;
  font-size: 10px;
  font-style: italic;
  font-weight: 500;
}

.icon-container {
  display: flex;
  align-items: center;
}

.icon-container span {
  margin-left: 5px;
}

.pointer {
  cursor: pointer;
}
.flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.items-center {
  align-items: center;
}
.ml3 {
  margin-left: 1rem;
}
.ml1 {
  margin-left: 0.5rem;
}
.ttc {
  text-transform: capitalize;
}
.b {
  font-weight: bold;
}
.gray {
  color: #818897;
}
.workflows-details-form .ant-form-item-label {
  text-align: left;
}
.workflows-details-form .ant-form-item-label > label::after {
  display: none;
}
.workflows-details-form .add-email-section .add-email-form-container {
  width: 100%;
  float: left;
  background-color: #fbfcfd;
  padding: 13px 10px;
}
.workflows-details-form .add-email-section .add-email-form-container .ant-row {
  flex-flow: column;
  margin-bottom: 15px;
}
.workflows-details-form
  .add-email-section
  .add-email-form-container
  .ant-row
  .ant-col {
  width: 100%;
  float: left;
  display: contents;
}
.workflows-details-form
  .add-email-section
  .add-email-form-container
  .ant-row:last-child {
  margin-bottom: 0;
}
.workflows-details-form .ant-row.add-email-section {
  position: relative;
}
.workflows-details-form .ant-row.add-email-section {
  margin-bottom: 15px;
}
.workflows-details-form .ant-row.add-email-section .add-email-form-close {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  background-color: #eef0f4;
  color: #818897;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  position: absolute;
  right: -10px;
  top: -10px;
  cursor: pointer;
}
.workflows-history-table-header {
  width: 100%;
  float: left;
  margin: 15px 0;
  padding: 10px 15px;
  background-color: #fbfcfd;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.workflows-history-table-header .ant-select.ant-select-single,
.workflows-history-table-header
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: #f5f6f9;
  border: 1px solid #f5f6f9;
}
.full-width {
  width: 100%;
  float: left;
}
.workflows-history-table-header .ant-checkbox-inner {
  width: 24px;
  height: 24px;
  background-color: #fbfcfd;
  border-color: #383b40;
}
.workflows-history-table-header .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff;
}
.workflows-history-table-header .ant-checkbox-inner::after {
  top: 47%;
  left: 24%;
  width: 7.714286px;
  height: 12.142857px;
}
.workflows-history-table-header
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
.workflows-history-table-header
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-placeholder {
  color: #363746;
}
.workflows-history-table-header .ant-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
  color: #383b40;
}
.workflows-history-table-header .ant-btn {
  border-color: transparent;
  box-shadow: none;
  background-color: transparent;
  color: #383b40;
}
.workflows-history-table-header .ant-btn span {
  text-decoration: underline;
  color: #383b40;
}
.ant-select-disabled .ant-select-selection-item {
  color: #4f4d4d;
}
.input-label {
  color: #818897;
  font-size: 14px;
  padding: 10px;
}

.user-name{
  display: flex;
}