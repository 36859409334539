#toggleModal {
    display: none;
}

.user-profile {
    border-radius: 25px;
    width: 300px;
}

.sub-heading {
    font-size: 16px;
    margin-top: 20px;
}

.profile {
    font-size: 13px;
    color: blue; 
    font-weight: bold;
}