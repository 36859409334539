body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.center-loader {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.fixed-center-loader {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
}

.mb0 {
	margin-bottom: 0;
}
.mb3 {
	margin-bottom: 2rem;
}

.ant-btn-primary {
	background-color: #252ce1 !important;
	border-color: #252ce1 !important;
	border-radius: 5px !important;
}

p b {
	color: #252ce1;
	cursor: pointer;
}
