
.cloud-urls-list .ant-list-item:nth-child(even)  {
    background: #F5F6F9;
}

.url-row {
    padding: 0 16px ;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.url-span{
    width: 70%;
}

.add-new-link-text {
    margin: 5px 0;
    display: flex;
    width: 50%;
}

.url-validation-err-text{
    color:  #9B1C00;
}