.input-field {
	padding: 3px 8px;
	border: 1px solid #383b40;
	border-radius: 2px;
	width: 100%;
	color: #363746;
	/*margin-bottom: 2rem;*/
}

.eye {
	position: absolute;
	right: 10px;
	top: 65%;
	cursor: pointer;

	height: 20px;
}
.input-label {
	color: #818897;
	font-size: 14px;
}
.ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active{
	color: aqua;
}
