.workflow-collapse-container {
    width: 100%;
    float: left;
}
.workflow-collapse-container .ant-collapse {
    width: 100%;
    float: left;
}
.workflow-collapse-container .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
font-size: 30px;
    color: #818897;
}
.workflow-collapse-container .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
    margin-bottom: 10px;
}
.workflow-collapse-container .ant-upload.ant-upload-drag p.ant-upload-hint {
    color: #A6ACB7;
    font-size: 12px;
}
.workflow-collapse-container .ant-btn {
    font-weight: 500;
font-size: 12px;
line-height: 20px;
color: #1D1D1F;
    background: #fff;
    border: 1px solid #000000;
box-sizing: border-box;
border-radius: 5px;
}
.KeyValueContainer {
	width: 100%;
	float: left;
	border: 1px solid #EEF0F4;
	padding: 15px 10px;
	margin-top: 10px;
}
.KeyValueContainer .ant-input{
    margin-top: 10px;
    font-size: 12px;
    color: #616775;
}
.KeyValueInputClose {
    display: flex;
    align-items: center;
}
.KeyValueInputClose .anticon {
    width: 40px;
    padding: 10px 5px;
    cursor: pointer;
}
.KeyValueContainer .ant-collapse-content {
    background-color: #FBFCFD;
    border-top: 1px solid #d9d9d9;
}
.workflow-collapse-container .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px;
    background-color: #FBFCFD;
        width: 100%;
    float: left;
}
.workflow-collapse-container .ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid #d9d9d9;
    margin-top: 15px;
    background-color: #fff;
}
.workflow-collapse-container .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    padding: 12px 16px;
    padding-right: 40px;
    background-color: #FBFCFD;
}
.workflow-collapse-container .ant-collapse > .ant-collapse-item {
    border-bottom: none;
    /* border-top: 2px solid #EEF0F4; */
    margin-top: 15px;
        width: 100%;
    float: left;
}
.workflow-collapse-container .ant-collapse {
    background-color: #fff;
        border: none;
}
.workflow-collapse-container .ant-collapse-content {
    border-top: solid 1px #EEF0F4;
}
.workflow-collapse-container .ant-collapse > .ant-collapse-item:first-child {
    margin-top: 0;
}
.Notifications-collapse-container {
    width: 100%;
    float: left;
}
.notifications-collapse-card {
        width: 100%;
    float: left;
    border: 1px solid #EEF0F4;
    padding: 15px 10px;
    margin-top: 10px;
    position: relative;
}
.notifications-event-select .ant-select-multiple .ant-select-selection-item-remove{
    display: none;
}
.notifications-event-select .ant-select-multiple .ant-select-selection-item {
    background-color: transparent;
    border-color: #fff;
    font-size: 12px;
    color: #383B40;
    font-weight: 600;
    margin: 0;
    padding: 0;
}
.notifications-event-select .ant-select-multiple .ant-select-selection-item-content {
    margin: 0;
}
.notifications-event-select .ant-select-multiple .ant-select-selection-item-content:after {
    content: ",";
}
.ant-select-selection-overflow .ant-select-selection-overflow-item:nth-last-child(2) .ant-select-selection-item-content:after{
    display: none;
}
.workflow-collapse-container .ant-form-vertical .ant-form-item-label > label,
.workflow-collapse-container .ant-form-item-label > label,
.input-label {
    height: auto;
    font-size: 12px;
    color: #616775;
}
.notifications-email-select .ant-select-multiple .ant-select-selection-item {
    font-size: 12px;
    background-color: #252CE1;
    color: #fff;
    border-radius: 25px;
}
.notifications-email-select .ant-select-multiple .ant-select-selection-item-remove > .anticon {
    vertical-align: -0.2em;
    color: #fff;
}
.notifications-collapse-card .ant-form-item:last-child {
    margin-bottom: 0;
}
.notifications-collapse-card .DropdownAction .ant-btn{
border: none;
    padding: 0;
    margin: 0;
}
/* .notifications-collapse-card .DropdownAction .ant-dropdown-trigger {
    display: none;
} */
.notifications-collapse-card .DropdownAction .anticon {
    font-size: 20px;
    color: #363746;
}
.notifications-collapse-card .DropdownAction {
        position: absolute;
    right: 5px;
    top: 10px;
        z-index: 2;
}
.selectEventDropdown .ant-select-item .ant-select-item-option-content{
    position: relative;
    overflow: visible;
}
.selectEventDropdown .ant-select-item .ant-select-item-option-content:after{
    content: '';
    position: absolute;
    right: -3px;
    width: 20px;
    height: 20px;
    border: 2px solid #DADEE8;
    border-radius: 2px;
}
.selectEventDropdown .ant-select-item.ant-select-item-option-selected .ant-select-item-option-content:after {
    right: -17px;
    z-index: 3;
    background-color: #252CE1;
    border-color: #252CE1;
}
.selectEventDropdown .ant-select-item.ant-select-item-option-selected .ant-select-item-option-state .anticon{
    color: #fff;
    position: relative;
    z-index: 9;
}
.workflow-drawer-footer {
    width: 100%;
    float: left;
}
.rc-virtual-list-scrollbar{
    display: flex !important;
}

.notifications-view .ant-collapse-content-box{
    height: 600px !important;
}
.TriggerRadioGroup {
    margin-bottom: 15px;
}
.TriggerRadioGroup .ant-radio-wrapper {
font-weight: 400;
font-size: 12px;
line-height: 20px;
color: #383B40;
}
.SelectOverflowDec {
    width: 100%;
    float: left;
    font-weight: 400;
font-size: 12px;
line-height: 14px;
letter-spacing: 0.01em;
color: #A6ACB7;
margin-top: 10px;
}
.mb-0 {
    margin-bottom: 0 !important;
}
.TriggerManualText {
    width: 100%;
    float: left;
    font-weight: 400;
font-size: 12px;
line-height: 20px;
color: #616775;
}
.Workflow-Definition-container {
    width: 100%;
    float: left;
}
.Workflow-Definition-label {
    width: 100%;
    float: left;
        height: auto;
    font-size: 12px;
    color: #616775;
        padding: 0 0 8px;
            line-height: 1.5715;
}
.w-100 {
    width: 100% !important;
}
.Workflow-Definition-container .ant-collapse-item {
width: 100%;
    float: left;
}
.workflow-collapse-container .Workflow-Definition-container .ant-collapse > .ant-collapse-item {
    background: #F0F1FB;
    border-radius: 5px;
        overflow: hidden;
}
.workflow-collapse-container .Workflow-Definition-container .ant-collapse-content {
    background: #F0F1FB;
    border-top: solid 1px #EEF0F4;
    width: 100%;
    float: left;
}
.workflow-collapse-container .Workflow-Definition-container .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
        width: 100%;
    float: left;
}
.workflow-collapse-container .Workflow-Definition-container .ant-collapse > .ant-collapse-item > .ant-collapse-header {
background-color: #EAEBF9;
}

 .Workflow-Input-container .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    background-color: #ddd;
    }

    .Workflow-Input-container .ant-collapse-header {
        background-color: #ddd!important;
    }
    .workflow-Input-container .ant-collapse-header {
        background-color: #ddd!important;
    }
.workflow-collapse-container .Workflow-Definition-container .ant-collapse {
    background-color: transparent;
    border: none;
}
.Workflow-Definition-container .ant-form-horizontal .ant-form-item{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.Workflow-Definition-container .ant-form-horizontal .ant-form-item.form-text-area{
    align-items: flex-start;
}
.Workflow-Definition-container .ant-form-horizontal .ant-form-item.form-text-area .ant-form-item-label > label {
    padding-top: 10px;
}
.Workflow-Definition-container .ant-form-horizontal .ant-form-item .ant-form-item-label {
    padding-bottom: 0;
}
.Workflow-Definition-container .ant-form-horizontal .ant-form-item .ant-input {
    margin-top: 0;
}
.Workflow-Definition-container .ant-form-horizontal .ant-form-item-label {
    min-width: 95px;
}
.workflow-collapse-container .Workflow-Definition-container .ant-collapse-content .ant-collapse-content-box{
    background-color: #F0F1FB;
}
.Workflow-Definition-container .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}
.Workflow-Definition-container .Arguments-Action-Set {
    width: 100%;
    float: left;
        display: flex;
    flex-direction: row;
    align-items: center;
        margin-bottom: 8px;
}
.Workflow-Definition-container .Arguments-Action-Set:last-child {
    margin-bottom: 0;
}
.Workflow-Definition-container .Arguments-remove {
        color: #D14545;
    margin: 0 20px;
    font-size: 18px;
    cursor: pointer;
}
.Workflow-Definition-container .Arguments-Action-Set .ant-btn {
        border: 0.5px solid #383B40;
    border-radius: 2px;
    background-color: transparent;
    color: #383B40;
}
.workflow-collapse-container .ant-btn.trigger-rmove-btn {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    padding: 0;
    color: #D14545;
    box-shadow: none;
    height: auto;
}
.workflow-collapse-container .ant-btn.trigger-rmove-btn span {
    margin-left: 5px;
}
.tabHeader {
        display: flex;
    align-items: center;
    font-weight: 700;
font-size: 11px;
line-height: 20px;
color: #1D1D1F;
}
.tabHeader span {
    margin-left: 12px;
}
.workflow-collapse-container [data-rbd-droppable-id="droppable"] {
width: 100%;
float: left;
}
.DraggableSet {
    width: 100%;
    float: left;
}
.workflow-collapse-container .DraggableSet > div {
   width: 100%;
float: left; 
margin-bottom: 15px;
}

.depends .ant-form-item-control{
width: 189px;
margin-left: 5px !important;
}

.commounForm .ant-form-item-control{
    margin-left: 34px;
}

#Workflow_Definition .ant-collapse-header {
    background-color: #f0f1fb!important;
}

.ant-btn-group>.ant-btn:first-child, .ant-btn-group>.ant-btn:last-child {
    border-radius: 2px
}