.dropzone {
	text-align: center;
	padding: 10px;
	border: 3px dashed #eeeeee;
	background-color: #fafafa;
	color: #bdbdbd;
	cursor: pointer;
	margin-bottom: 20px;
}

.zip-text {
	font-size: 12px;
	color: #a6acb7;
}

.selected-file-wrapper {
	text-align: center;
}

.btn-upload {
	border: none;
	background-color: #252ce1;
	color: #fff;
	border-radius: 5px;
	padding: 5px 15px;
	cursor: pointer;
}

.file-container {
	display: flex;
	background-color: #eef0f4;
	border-radius: 2px;
	padding: 2px 6px;
	margin-bottom: 20px;
}

.file-name {
	flex-grow: 1;
	cursor: pointer;
}

.file-name-color {
	color: #252ce1;
	font-weight: bold;
}

.cross-icon {
	cursor: pointer;
	padding: 0px 10px;
}
