.formField {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.input {
  width: 300px;
  height: 36px;
  padding-left: 8px;
  font-family: "Nunito Sans", sans-serif;
}

.authFormSubmit {
  background-color: #252ce1;
  width: 100%;
  height: 36px;
  color: white;
  border-radius: 4px;
  border: 0px;
  cursor: pointer;
}
.signinError {
  color: red;
  font-size: 15px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 5px;
}
